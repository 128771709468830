import React, { Component } from "react"
import axios from "axios"

import { Layout, SEO, HeroBanner, Image } from "../../components"
import { validateArray } from "../../components/utilities/functions"
import moment from "moment"
import "./index.scss"

class BlogPage extends Component {
	state = {
		blogs:
			this.props.pageContext && validateArray(this.props.pageContext.blogs)
				? this.props.pageContext.blogs
				: [],
		loader: false,
		skip: 5,
		limit:
			(this.props.pageContext &&
				this.props.pageContext.node &&
				this.props.pageContext.node.blog_limit) ||
			5,
		loadMore: false,
		loadMoreBtn: true,
		count:
			this.props.pageContext && this.props.pageContext.blogCount
				? this.props.pageContext.blogCount
				: 5,
	}

	callBlogList = () => {
		const environment =
			process.env.GATSBY_CONTENTSTACK_ENVIRONMENT || "development"
		const CDNURL = `https://cdn.contentstack.io/v3/content_types/blog_details_page/entries?environment=${environment}&locale=en-us&include_count=true`

		let params = {
			api_key: `${process.env.GATSBY_CONTENTSTACK_API_KEY || ""}`,
			authtoken: `${process.env.GATSBY_CONTENTSTACK_DELIVERY_TOKEN || ""}`,
			skip: this.state.skip,
			limit: this.state.limit,
			desc: "date",
		}

		axios
			.get(CDNURL, { params })
			.then(response => {
				if (response && response.data && response.data.entries) {
					this.setState({
						blogs: this.state.blogs.concat(response.data.entries),
						skip: this.state.skip + response.data.entries.length,
						loader: false,
						loadMore:
							response.data.entries.length !== this.state.limit ? false : true,
						loadMoreBtn: true,
					})
					if (document && document.getSelection()) {
						document.getSelection().removeAllRanges()
					}
				}
			})
			.catch(err => {
				console.log(err)
			})
	}

	handleLoadMoreClick = () => {
		this.setState({
			loader: true,
		})

		this.callBlogList(this.state.skip)
	}

	render() {
		const { pageContext} = this.props
		const { url, seo, hero, post_image, uid='', locale="" } = pageContext.node || {}
		const img_url = post_image ? post_image.url : "";

		const extension = {
			contenttype:(pageContext?.contentType || ''),
			uid:uid,
			locale:locale
		}

		return (
			<Layout pageURL={url} className="blogLP-wrapper">
				<SEO pageURL={url} {...seo} extension={extension} />
				{hero && (
					<HeroBanner
						image={hero.hero_image}
						imageAlt={hero.image_alt}
						heading={hero.heading}
						hasContainer={true}
						hasOverlayBG={true}
					/>
				)}

				<div className="container container--980">
					<div className="blogs">
						{validateArray(this.state.blogs) &&
							this.state.blogs.map((item, index) => (
								<div className="blog-post row" key={index}>
									<div className="col-12 col-768-3 post-image">
										<a className="link" href={item.url}>
											{(item.blog_image && (
												<Image
													className="img-responsive blog-img"
													data={{
														image: item.blog_image,
														image_alt: item.image_alt,
													}}
												/>
											)) || (
												<Image
													className="img-responsive blog-img"
													data={{
														image: { url: img_url },
														image_alt: "Rawweng Blog Thumbnail",
													}}
												/>
											)}
										</a>
									</div>
									<div className="col-12 col-768-9">
										{item.title && <h3>{item.title}</h3>}
										<p className="date">
											{item.author_name && (
												<span className="author">By {item.author_name}, </span>
											)}
											{item.date && (
												<span className="date">
													{" "}
													{moment(item.date).format("MMM Do YYYY")}
												</span>
											)}
										</p>
										{item.short_description && (
											<div
												dangerouslySetInnerHTML={{
													__html: item.short_description,
												}}
											/>
										)}
										{item.url && (
											<a className="link" href={item.url}>
												Read More &rarr;
											</a>
										)}
									</div>
								</div>
							))}

						{this.state.loader && (
							<div className="loader-wrapper">
								<div className="blog-loader" />
							</div>
						)}
						{this.state.loadMoreBtn &&
							!this.state.loader &&
							this.state.count > this.state.skip && (
								<div className="text-center spacing-30">
									<button
										className="btn btn-primary btn-rounded"
										onClick={this.handleLoadMoreClick}
										type="button"
									>
										Load More
									</button>
								</div>
							)}
					</div>
				</div>
			</Layout>
		)
	}
}

export default BlogPage
